body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#sidebar-menu>ul>li>a {
  padding: 5px 20px;
  font-size: 13px;
}

#sidebar-menu ul li a i {
  font-size: 13px;
}

.topbar .topbar-left .logo span img {
  height: 30px;
}

.topbar .topbar-left .logo i img {
  height: 15px;
}